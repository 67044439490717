<template>
  <!-- 模板文件操作按钮组 -->
  <div>
    <icon-button
      content="下载模板"
      icon="iconfont iconxiazai"
      :templateCode="scope.row.templateFileId"
      btnType="download"
      v-if="!canSubmitFlag && !scope.row.addtype||scope.row.isAdd"
    />
    <el-upload
      action=""
      class="upload-demo"
      :http-request="function (url) {
          return Upload(url, scope.row, 1);
        }
      "
      v-if="!canSubmitFlag && !scope.row.addtype||scope.row.isAdd"
      accept=".docx"
      :show-file-list="false"
    >
      <icon-button content="上传" icon="iconfont iconshangchuan" />
    </el-upload>
    <icon-button
      content="删除"
      icon="iconfont iconshanchu1"
      v-if="scope.row.isfUserUpload && !canSubmitFlag"
      @click="deleteTemplate(scope.row, 1)"
    />
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { IconButton },
  props: {
    canSubmitFlag: {
      type: Boolean,
      default: false
    },
    scope: {}
  },
  data () {
    return {}
  },

  methods: {
    // 文件上传
    Upload (param, row, type) {
      this.$emit('Upload', param, row, type)
    },
    // 文件删除
    deleteTemplate (row, type) {
      this.$emit('deleteTemplate', row, type)
    }
  }
}
</script>
