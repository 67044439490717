var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"visible":_vm.visible,"showFooter":false,"title":"选择电子签署人"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('base-table',{ref:"tableData",staticClass:"table",attrs:{"columns":_vm.columns,"showPage":false,"tableAttrs":{
      data: _vm.tableData,
      stripe: false,
      border: true,
      spanMethod: _vm.objectSpanMethod,
    },"isCaculateHeight":false,"webPage":true},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.$index + 1))]}},{key:"signerType",fn:function(scope){return [(scope.row.signerType)?_c('base-select',{attrs:{"options":_vm.getDictLists('SEAL_TYPE'),"selectedField":['dictId', 'dictName'],"disabled":true},model:{value:(scope.row.signerType),callback:function ($$v) {_vm.$set(scope.row, "signerType", $$v)},expression:"scope.row.signerType"}}):_c('span',[_vm._v("-")])]}},{key:"sealAdminId",fn:function(scope){return [(scope.row.signerType!=='3')?_c('base-select',{attrs:{"options":scope.row.selectFlag
            ? _vm.sealAdmins[scope.row.selectIndex]
            : scope.row.sealAdmins,"selectedField":['keyId', 'personnelName']},model:{value:(scope.row.sealAdminId),callback:function ($$v) {_vm.$set(scope.row, "sealAdminId", $$v)},expression:"scope.row.sealAdminId"}}):_c('span',[_vm._v("-")])]}}])}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{attrs:{"type":"primary","label":"提交"},on:{"click":_vm.submit}}),_c('base-button',{attrs:{"label":"关闭","type":"default"},on:{"click":_vm.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }