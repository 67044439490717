// import router from '@/router/index'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { getTenant, getToken } from '../../utils/auth/auth-storage'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL
})

/**
 * 构建跳转参数配置
 * @param {*} path
 * @param {*} row
 * @returns
 */
const buildOpenUrl = (path, row) => {
  const { keyId, currentFileFileSuffix, fileName, fileId, documentName, lockValue } = row
  const nickName = store.state.user.userInfo.nickName
  // 文件完整名称
  // const fileFullName = fileName + '.' + currentFileFileSuffix
  // 构建url参数
  const { href } = router.resolve({
    path,
    query: {
      fileName: fileName,
      fileId: fileId,
      fileDetailId: keyId,
      extendName: currentFileFileSuffix,
      documentName: documentName,
      nickName: nickName,
      lockValue: lockValue,
      token: getToken(),
      tenantId: getTenant()
    }
  })
  return (
    'WebOffice://|Officectrl.com|' + decodeURI(window.location.origin) + href
  )
}

/**
 * 编辑文件
 */
export const editFileByOffice = (row) => {
  const openUrl = buildOpenUrl('/edit.html', row)
  window.open(openUrl, '_self')
}
