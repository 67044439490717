<template>
  <base-dialog
    :visible.sync="visible"
    :showFooter="false"
    title="选择电子签署人"
  >
    <base-table
      :columns="columns"
      :showPage="false"
      :tableAttrs="{
        data: tableData,
        stripe: false,
        border: true,
        spanMethod: objectSpanMethod,
      }"
      :isCaculateHeight="false"
      ref="tableData"
      :webPage="true"
      class="table"
    >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <!-- 盖章类型 -->
      <!-- <template slot="positionName" slot-scope="scope">
        <base-select
          v-if="scope.row.positionName"
          v-model="scope.row.positionName"
          :options="scope.row.selectFlag?sealAdmins[scope.row.selectIndex]:scope.row.newSealAdmins"
          :selectedField="['keyId', 'personnelName']"
        ></base-select>
        <span v-else>-</span>
      </template> -->
      <!-- 电子章类型 -->
      <template slot="signerType" slot-scope="scope">
        <base-select
          v-if="scope.row.signerType"
          v-model="scope.row.signerType"
          :options="getDictLists('SEAL_TYPE')"
          :selectedField="['dictId', 'dictName']"
          :disabled="true"
        ></base-select>
        <span v-else>-</span>
      </template>
      <!-- 电子章签署人 -->
      <template slot="sealAdminId" slot-scope="scope">
        <base-select

          v-if="scope.row.signerType!=='3'"
          v-model="scope.row.sealAdminId"
          :options="
            scope.row.selectFlag
              ? sealAdmins[scope.row.selectIndex]
              : scope.row.sealAdmins
          "
          :selectedField="['keyId', 'personnelName']"
        ></base-select>
        <span v-else>-</span>
      </template>
    </base-table>
    <div slot="footer" class="dialog-footer">
      <base-button type="primary" @click="submit" label="提交" />
      <base-button @click="close" label="关闭" type="default" />
    </div>
  </base-dialog>
</template>

<script>
import { signApi } from '@/api/businessApi'
import { signatoryTable } from '../utils/config'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { getDictLists } from '@/filters/fromDict'
export default {
  components: { BaseTable, BaseDialog, BaseSelect, BaseButton },
  props: {
    sealAdmins: {
      type: Array,
      default: () => []
    },
    signShow: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      getDictLists
    }
  },
  // 计算属性 类似于data概念
  computed: {
    visible: {
      get () {
        return this.signShow
      },
      set (val) {
        this.$emit('update:signShow', val)
      }
    },
    columns () {
      return signatoryTable(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    close () {
      this.visible = false
      let pass = true

      this.tableData.forEach((item) => {
        if (item.signerType === '3') { // 平台章的话就不做校验
          pass = true
        } else {
          if (!item.sealAdminId && item.signerConfigIdRowSpan !== 0) {
            pass = false
          }
        }
      })
      if (!pass) {
        this.$emit('update:chosePass', false)
      }
    },
    submit () {
      console.log(this.tableData)
      let pass = true
      let fileName = ''
      this.tableData.forEach((item) => {
        if (item.signerType === '3') { // 平台章的话就不做校验
          pass = true
          fileName = item.finFileListName
        } else {
          if (!item.sealAdminId && item.signerConfigIdRowSpan !== 0) {
            pass = false
            fileName = item.finFileListName
          }
        }
      })
      if (!pass) {
        this.warning('请选择' + fileName + '的签署人')
        return false
      }
      const newTableData = []
      // 构造数据
      this.tableData.forEach((item) => {
        if (item.sealAdminId) {
          if (item.signerConfigIdRowSpan !== 0) {
            newTableData.push(item)
          }
        }
      })

      signApi.saveOrUpdateSignConfig(newTableData).then((res) => {
        if (res.data) {
          this.$emit('update:chosePass', true)
          this.success('提交成功')
          this.close()
        } else {
          this.error('提交失败')
        }
      })
    },
    // 获取列表
    getFilelist () {},

    // 合并
    objectSpanMethod ({ row, column }) {
      if (column.property === 'finFileListName') {
        const _row = row.finFileListNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'finFileListDetailName') {
        const _row = row.finFileListDetailNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'signerType' || column.property === 'sealAdminId') {
        const _row = row.signerConfigIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
  },

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {}
}
</script>
<style lang='scss' scoped>
</style>
